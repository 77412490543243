import React from "react";
import Header from "./components/header";
import Conferences from "./components/conferences";
import PastConferences from "./components/past_conferences";
import Footer from "./components/footer";
import ConferenceEdition, {IConferenceEditionProps} from "./components/conference_edition";


import dulcan from "./images/speakers/pitesti/ctin_dulcan.png";
import coman from "./images/speakers/pitesti/maria_coman.png";


import Schedule, {IScheduleEntry} from "./components/schedule";


const App: React.FunctionComponent = () => {
    const pitestiEditionProps: IConferenceEditionProps = {
        className: "container-fluid conference-edition py-5",
        containerId: "opera",
        title: "Fain & Simplu x Pitești",
        subtitle: "IUBIREA APROAPELUI ÎN TIMPURI CARE NE ÎNDEPĂRTEAZĂ",
        description: <div>
            <p style={{textAlign: "justify"}}>Există momente în viață când tot ce avem nevoie este să ne simțim aproape unii de ceilalți. Să ne amintim că, dincolo de diferențe, ceea ce ne leagă e mai puternic decât ceea ce ne desparte. Deloc întâmplător, luna decembrie pare să poarte în sine acest mesaj – al iubirii aproapelui, al gesturilor simple făcute din inimă și al conexiunii - care aduc liniște, vindecare și sens.</p>
            <p style={{textAlign: "justify"}}>Pe 7 decembrie, la Filarmonica din Pitești, vă invităm la ultima conferință Fain & Simplu din acest an. Va fi o seară în care vom descoperi împreună puterea iubirii care (ne) apropie, care dă sens și ne învață să fim mai buni, atât cu noi, cât și cu cei din jur. </p>
            <p style={{textAlign: "justify"}}>Vor fi prezenți profesorul Constantin Dulcan, un invitat de suflet al conferințelor Fain & Simplu, alături de Maria Coman, care ni se va alătură în premieră. Împreună, ne vor ajuta să înțelegem ce înseamnă să fim generoși, să ne ascultăm unii pe alții cu blândețe și să (re)descoperim bucuria de a dărui și de a primi. Iubire.</p>
            <p style={{textAlign: "justify"}}>Un moment deosebit al serii va fi recitalul de colinde și Psalmi oferit de Maria Coman care va aduce liniște și multă emoție în sufletele tuturor celor prezenți. După acest moment, Maria, în dialog cu Mihai Morar, va vorbi despre puterea vindecătoare a muzicii sacre și a colindelor și ne va dezvălui cum acestea ne pot ajuta să regăsim liniștea interioară și să ne reconectăm cu valorile esențiale ale credinței.</p>
            <p style={{textAlign: "justify"}}>Vă așteptăm, pe 7 decembrie la Filarmonica din Pitești, să redescoperim, împreună, frumusețea conexiunii umane și a valorile care nu vor pieri niciodată: dragostea, credința și generozitatea.</p>
        </div>,
        ticketsUrl: "https://tickets.fainsisimplu.ro/",
        speakers: [
            {
                name: "Prof. Dr. Dumitru Constantin Dulcan",
                photo: dulcan,
                description: <div style={{textAlign: "justify"}}>
                    <p><b>Prof. Dr. Dumitru Constantin Dulcan</b> este medic neurolog, psihiatru și autor cunoscut pentru modul în care îmbină știința cu spiritualitatea. Cărțile sale, precum <i>"Inteligența materiei"</i> și <i>"Mintea de dincolo"</i>, au inspirat milioane de oameni, oferind o perspectivă profundă asupra legăturii dintre gândire, conștiință și sănătate. Cu o experiență de peste 50 de ani în medicină, Dr. Constantin Dulcan susține conferințe și scrie despre puterea minții, subconștient, vindecarea holistică și sensul vieții, abordând teme care ne ajută să trăim mai conștient și mai echilibrat.</p>
                </div>,
                youtubeLink: "https://www.youtube.com/watch?v=e-RxQi3QSxs",
                youtubeName: "Prof. Dr. Dumitru Constantin Dulcan",
                sex: "f"
            },
            {
                name: "Maria Coman ",
                photo: coman,
                description: <div style={{textAlign: "justify"}}>
                    <p>Prin aparițiile sale în mediul online și la televizor, <b>Maria Coman</b> a reușit să depășească granițele aprecierii naționale, devenind o voce care răsună în sufletele credincioșilor din întreaga lume. A susținut concerte în Franţa, Germania, Austria şi în alte părţi ale lumii unde diaspora românească este foarte numeroasă, pe scene prestigioase ca Musikverein din Viena, dar şi pe cea a Ateneului Român sau a Filarmonicii din Pitești. Interpretările sale, pline de har și încărcate de emoție, au devenit adevărate rugăciuni cântate, oferind speranță, putere sufletească și o conexiune mai profundă cu Dumnezeu.</p>
                </div>,
                sex: "f"
            }
        ]
    }
    const agendaAteneu: IScheduleEntry[] = [
        {
            time: "14:00",
            description: "Deschidere"
        },
        {
            time: "14:00 - 15:00",
            description: "Acces public și socializare"
        },
        {
            time: "15:00 - 16:00",
            description: "Conversatie plus Recital de colinde și Psalmi cu Maria Coman"
        },
        {
            time: "16:00 - 16:45",
            description: "Pauză de socializare și poze"
        },
        {
            time: "16:45 - 18:00",
            description: "A doua sesiune cu un Invitat Surpriza"
        },
        {
            time: "18:00 - 18:45",
            description: "Pauză de socializare și poze"
        },
        {
            time: "18:45 - 20:15",
            description: "A treia sesiune cu domnul Profesor Doctor Constantin Dulcan"
        },
    ]


    return <>
        <Header />
        <Conferences />
        <ConferenceEdition {...pitestiEditionProps} />
        <Schedule title={'Agendă'} entries={agendaAteneu} />
        <PastConferences />
        <Footer />
    </>
}

export default App;